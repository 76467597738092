/* eslint-disable max-len */
import { get, uniqBy } from 'lodash';
import wretch from 'wretch';
import { differenceInDays, getTime } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';

import { DATE_FORMATS } from './../../constants';

export const newsArticleSchema = (story, config) => {
  const imageObject = {
    '@type': 'ImageObject',
    url: `${story['hero-image-s3-key']
      ?.replace(/w=\d+/, 'w=1200')
      ?.replace(/h=\d+/, 'h=675')}&auto=format%2Ccompress&format=webp&q=60`,
    height: 675,
    width: 1200
  };

  const newsArticleSchema = {
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': story.url
    },
    image: imageObject,
    datePublished: formatInTimeZone(
      story['published-at'],
      'Asia/Kolkata',
      DATE_FORMATS.DATE_TIME_ZONE
    ),
    dateModified: formatInTimeZone(
      story['updated-at'],
      'Asia/Kolkata',
      DATE_FORMATS.DATE_TIME_ZONE
    ),
    author: {
      '@type': 'Person',
      name: story['author-name'],
      url: `/author/${story.authors[0].id}/${story.authors[0].slug}`
    },
    publisher: {
      '@type': 'Organization',
      name: 'NDTV Profit',
      logo: {
        '@type': 'ImageObject'
      }
    },
    description: story.seo['meta-description']
  };
  return newsArticleSchema;
};

export function getSyndicatedFrom(story) {
  return get(story, ['metadata', 'story-attributes', 'syndicatedfrom', 0]);
}

export function truncate(str, length) {
  const suffix = '...';
  return str.length < length ? str : str.substring(0, length - suffix.length) + suffix;
}
export function copyText(data = '') {
  const elem = document.createElement('textarea');
  elem.value = data;
  elem.setAttribute('readonly', '');
  elem.style.position = 'absolute';
  elem.style.left = '-9999px';
  document.body.appendChild(elem);
  elem.select();
  document.execCommand('copy');
  document.body.removeChild(elem);
}
export function isNotFillerStory(story) {
  const syndicatedFrom = getSyndicatedFrom(story);

  if (!syndicatedFrom) return true;

  const isFiller = syndicatedFrom.toLowerCase();
  return isFiller !== 'filler' && isFiller !== 'fillers';
}

export function skipTextElement(element, skipWords) {
  if (element.type === 'text') {
    return element.text.match(skipWords) === null;
  }
  return true;
}
/* eslint-disable */
export function slugify(string) {
  return string
    ?.toString()
    ?.toLowerCase()
    ?.replace(/\s+/g, '-') // Replace spaces with -
    ?.replace(/[^\w-]+/g, '') // Remove all non-word chars
    ?.replace(/--+/g, '-') // Replace multiple - with single -
    ?.replace(/^-+/, '') // Trim - from start of text
    ?.replace(/-+$/, ''); // Trim - from end of text
}
/* eslint-enable */
export function syndicatedSource(story) {
  const syndicatedFrom = getSyndicatedFrom(story);

  if (syndicatedFrom === 'thequint') {
    return 'TheQuint';
  } else if (syndicatedFrom === 'bloomberg') {
    return 'Bloomberg';
  } else if (syndicatedFrom === 'businessweek') {
    return 'businessweek';
  }
  return 'NDTV Profit हिंदी';
}

export function openLinkInNewTab(selector) {
  const linkList = document.querySelectorAll(selector);
  for (let i = 0; i < linkList.length; i++) {
    linkList[i].target = '_blank';
  }
}

export function changeUnicodeSymbolsColor(selector, story) {
  const storyStyleType = get(story, ['metadata', 'story-attributes', 'storystyletype', 0]);
  if (storyStyleType !== 'cards') return null;

  const linkList = document.querySelectorAll(selector);
  for (let i = 0; i < linkList.length; i++) {
    let text = linkList[i].innerHTML;
    text = text?.replace(/♥|♦/gi, function (matched) {
      return `<span style="color:red;">${matched}</span>`;
    });
    linkList[i].innerHTML = text;
  }
}

export function getTitleElement(elements) {
  return elements.find((element) => element.type === 'title');
}

export function titleText(card) {
  const text = getTitleElement(card['story-elements']);
  return text ? text.text : '';
}

export function getDuration(length) {
  const seconds = Math.floor(length / 1000);
  return `${Math.floor(seconds / 60)}m ${seconds % 60 < 10 ? `0${seconds % 60}` : seconds % 60}s`;
}
export function secondsToHms(d) {
  d = Number(d);
  const h = Math.floor(d / 3600);
  const m = Math.floor((d % 3600) / 60);
  const s = Math.floor((d % 3600) % 60);

  const hDisplay = h > 0 ? h + 'h ' : '';
  const mDisplay = m > 0 ? m + 'm ' : '';
  const sDisplay = s > 0 ? s + 's ' : '';
  return hDisplay + mDisplay + sDisplay;
}

export class LocalStorage {
  set(key, value) {
    global.localStorage.setItem(key, JSON.stringify(value));
  }

  get(key) {
    return JSON.parse(global.localStorage.getItem(key));
  }

  remove(key) {
    global.localStorage.removeItem(key);
  }
}

export function formatPrice(price, currency, type, minimumFractionDigits) {
  return price.toLocaleString(type, {
    style: 'currency',
    currency,
    minimumFractionDigits
  });
}

export function isPrivateMode() {
  return new Promise((resolve) => {
    const on = () => resolve(true); // is in private mode
    const off = () => resolve(false); // not private mode

    // Chrome & Opera
    if (window.webkitRequestFileSystem) {
      window.webkitRequestFileSystem(window.TEMPORARY, 1, off, on);
      return;
    }

    // Firefox
    if (
      /Firefox/.test(window.navigator.userAgent) ||
      'MozAppearance' in document.documentElement.style
    ) {
      const db = window.indexedDB.open('test');
      db.onerror = on;
      db.onsuccess = off;
      return;
      // if (!window.navigator.serviceWorker) { return on(); }
    }

    // IE10+ & Edge
    if (/Edge/.test(window.navigator.userAgent) || window.PointerEvent || window.MSPointerEvent) {
      if (window.indexedDB) {
        return off();
      } else {
        return on();
      }
    }

    // Safari
    if (/Safari/.test(window.navigator.userAgent)) {
      try {
        window.openDatabase(null, null, null, null);
        return off();
      } catch (_) {
        return on();
      }
    }

    // Others
    return off();
  });
}
export function convertToSlug(text = '') {
  return text
    ?.toLowerCase()
    ?.replace(/ /g, '-')
    ?.replace(/[^\w-]+/g, '');
}
export function getHeroImage(config, story) {
  const heroImageS3Key = get(story, [
    'alternative',
    'home',
    'default',
    'hero-image',
    'hero-image-s3-key'
  ])
    ? get(story, ['alternative', 'home', 'default', 'hero-image', 'hero-image-s3-key'])
    : get(story, ['hero-image-s3-key'])
    ? get(story, ['hero-image-s3-key'])
    : get(config, ['publisher', 'default_image_s3_key']);
  return heroImageS3Key;
}
export function sectionColor(config, sectionId) {
  const menuItem = get(config, ['layout', 'menu'], []).find(
    (menuItem) => menuItem['item-type'] === 'section' && menuItem['item-id'] === sectionId
  );
  return get(menuItem, ['data', 'color'], '#2b00f7');
}

export function getStoryHeading(story = {}) {
  return get(story, ['alternative', 'home', 'default', 'headline'])
    ? get(story, ['alternative', 'home', 'default', 'headline'])
    : get(story, 'headline');
}

export function getStockDetails(stocks = {}, index = 0) {
  return get(stocks, [index, 'STOCKID'], null);
}

export function checkStoryType(story) {
  const accessLevel = get(story, ['access-level-value'], null);
  if (accessLevel === 999) {
    return 'paid story';
  } else if (accessLevel === 100) {
    return 'metered story';
  } else {
    return 'free story';
  }
}

export function couponDeepLink(coupon, isReferral) {
  const couponType = isReferral ? 'referral' : 'coupon';
  return `bq-blue-subscription?${couponType}=${coupon}`;
}

export function getStaticConfig(keys) {
  const element = window && window.document && document.getElementById('initial-page');
  if (element) {
    const { config } = JSON.parse(element.innerText) || {};
    return get(config, keys, config);
  }
}

export function sortStoriesByLastPublishedAt(stories = []) {
  return stories.sort(
    (a, b) => parseFloat(b['first-published-at']) - parseFloat(a['first-published-at'])
  );
}

export function sortStoriesByLastUpdatedAt(stories = []) {
  return stories.sort(
    (a, b) => parseFloat(b['last-published-at']) - parseFloat(a['last-published-at'])
  );
}

// export function refreshAdSlots() {
//   window.googletag.pubads().refresh();
// }

export function getChildCollectionsUniqueStories(childCollections = [], limit = 20) {
  const items = [];

  for (const collection of childCollections) {
    // eslint-disable-next-line no-unused-vars
    if (get(collection, ['items', 'type']) !== 'collection') {
      const storyLimit =
        collection.slug === 'top-news'
          ? get(collection, 'items', []).slice(0, limit)
          : get(collection, 'items', []).slice(0, limit);
      Array.prototype.push.apply(items, storyLimit);
    }
  }
  // eslint-disable-next-line array-callback-return
  let stories = items.filter((item) => {
    if (item.type !== 'collection') {
      return item;
    }
  });

  stories = stories.map(({ story }) => story);

  return stories.length > 0
    ? uniqBy(stories, function (e) {
        return e.id;
      })
    : [];
}

const getPartnersData = () => {
  return wretch('/bqpartners')
    .get()
    .json((response) => response.list);
};

export async function getPartnerfromUtmSource() {
  const partners = await getPartnersData();
  const urlParams = new URLSearchParams(global.location.search);
  const utmSource = urlParams.get('utm_source');
  if (
    !urlParams.has('utm_source') ||
    !partners.find((partner) => String(partner.utm_source) === String(utmSource))
  ) {
    return 'no-partners';
  } else {
    return partners.find((partner) => String(partner.utm_source) === String(utmSource));
  }
}

export const replaceArrayItemByIndex = (arr, index, value) => [
  ...arr.slice(0, index),
  value,
  ...arr.slice(index + 1)
];

export function getDeafultPlanId() {
  const urlParams = new URLSearchParams(global.location.search);
  if (urlParams.has('default_plan')) {
    return parseInt(urlParams.get('default_plan'));
  }
  return 'no_default_plan';
}

// Missing utils method for datalayer-utils added
export function getDateFromTimestamp(stamp) {
  const t = parseInt(stamp);
  if (!t) {
    return '';
  }
  const dateObject = new Date(t);
  const year = dateObject.getFullYear();
  const month = dateObject.getMonth() + 1;
  const date = dateObject.getDate();

  const stringDate = `${year}-${month < 10 ? `0${month}` : month}-${date < 10 ? `0${date}` : date}`;
  return stringDate;
}
export function getTimeFromTimestamp(stamp) {
  const t = parseInt(stamp);
  if (!t) {
    return '';
  }
  const dateObject = new Date(t);
  const hours = dateObject.getHours();
  const minutes = dateObject.getMinutes();
  const stringTime = `${hours < 10 ? `0${hours}` : hours}:${
    minutes < 10 ? `0${minutes}` : minutes
  }`;
  return stringTime;
}
export function timeString12hr(timeString) {
  return new Date('1970-01-01T' + timeString + 'Z').toLocaleTimeString('en-US', {
    timeZone: 'UTC',
    hour12: true,
    hour: 'numeric',
    minute: 'numeric'
  });
}
export function getNumberOfDaysPassed(stamp) {
  const t = parseInt(stamp);
  if (!t) {
    return '';
  }
  const dateToday = new Date().getTime();
  const diff = (dateToday - stamp) / (1000 * 3600 * 24);
  return parseInt(diff);
}
export function getHoursSinceTimestamp(stamp) {
  const t = parseInt(stamp);
  if (!t) {
    return '';
  }
  const dateToday = new Date().getTime();
  const diff = (dateToday - stamp) / (1000 * 60 * 60);
  return parseInt(diff);
}
export function getExtendedUserInfo(data, field) {
  let value = '';
  for (const i in data.custom_field_values) {
    const fieldName = data.custom_field_values[i].field_name;
    const fieldValue = data.custom_field_values[i].value;
    if (fieldName === field && fieldValue) {
      value = fieldValue;
      break;
    }
  }
  return value;
}

// End

// Whatsapp opt-in for new customers
export const getWhatsappOptIn = async (phoneNumber) => {
  const respone = await fetch(`/whatsappoptin?phoneNumber=${phoneNumber}`);
  const data = await respone;
  console.log('Whatsapp Opted in success', data);
};

// Get the type of device based on window size
export const getDeviceType = (size) => {
  let deviceType;
  if (size.width < 480) {
    deviceType = 'mobile';
  } else if (size.width < 1024) {
    deviceType = 'tablet';
  } else if (size.width < 1200) {
    deviceType = 'laptop';
  } else {
    deviceType = 'laptop';
  }
  return deviceType;
};

// Get the Headline from the Story
export const getHeadline = (story, isAlternative = true) => {
  if (!story) {
    return null;
  }

  let headline = null;

  if (isAlternative) {
    headline = get(story, ['alternative', 'home', 'default', 'headline'], null);
  }

  if (!headline) {
    headline = story.headline;
  }

  return headline;
};

export const getReaction = (story) => {
  if (!story) {
    return null;
  }

  return get(story, ['metadata', 'story-attributes', 'reactions', '0'], null);
};

export const getSection = (story) => {
  if (!story) {
    return null;
  }
  // eslint-disable-next-line camelcase
  const fallback_section_name = get(story, ['sections', '0', 'name'], null);

  return get(story, ['sections', '0', 'display-name'], fallback_section_name);
};

export const getStorySlug = (story) => {
  if (!story) {
    return null;
  }

  return `/${get(story, ['slug'], null)}`;
};

export const getImage = (story) => {
  if (!story) {
    return null;
  }

  return get(story, ['hero-image-s3-key'], null);
};

export const getAuthor = (story) => {
  if (!story) {
    return null;
  }

  const authorId = get(story, ['author-id'], null);
  if (!authorId) {
    return null;
  }

  const authorList = story.authors || [];
  if (!authorList.length) {
    return null;
  }

  const authorObj = authorList.find((c) => c.id === authorId);
  if (!authorObj) {
    return null;
  }

  return {
    ...authorObj,
    authorSlug: `/author/${authorObj.id}/${authorObj.slug}`
  };
};

export const isBqBlueExclusiveStory = (story) => {
  if (!story) {
    return false;
  }

  return (story.tags || []).some((c) => c.name === 'bqblue');
};

export const getSubHeadline = (story) => {
  if (!story) {
    return null;
  }

  return get(story, ['subheadline'], null);
};

export const getSummary = (story) => {
  if (!story) {
    return null;
  }

  return get(story, ['summary'], null);
};

export const calculateExpiryDate = (subscriptionEndDate) =>
  subscriptionEndDate && differenceInDays(new Date(subscriptionEndDate), new Date());

export const getTodayDate = (type) => formatInTimeZone(new Date(), 'Asia/Kolkata', type);

export const formatDate = (date, type) => {
  if (!date) {
    return null;
  }

  try {
    return formatInTimeZone(new Date(date), 'Asia/Kolkata', type);
  } catch (error) {}

  return null;
};

export const getNowUnixTime = () => getTime(new Date());

export const formatPublishedDate = (date) =>
  formatDate(date, DATE_FORMATS.HOUR_MINUTE_MERIDIEM) +
  ' IST, ' +
  formatDate(date, DATE_FORMATS.DATE_MONTH_YEAR);

export const gethhmm = (timestamp) => {
  return new Date(timestamp).toLocaleTimeString('en-Us', {
    timeZone: 'Asia/Kolkata',
    hour: '2-digit',
    minute: '2-digit'
  });
};

export const getddmmyyyy = (timestamp) => {
  return formatInTimeZone(timestamp, 'Asia/Kolkata', 'dd MMM yyyy, hh:mm a').slice(0, 11);
};

export const getStoryDate = (date) => {
  if (!date) {
    return null;
  }
  return gethhmm(date) + ' IST, ' + getddmmyyyy(date);
};

// to check cookies

export function checkCookie(tag) {
  // Get cookie using our custom function
  const isExist = getCookie(tag);
  if (isExist) {
    // getCookie(tag)
    return isExist;
  } else {
    return false;
  }
  // return false
}

// to get the cookies

export function getCookie(name) {
  // Split cookie string and get all individual name=value pairs in an array
  if (typeof window !== 'undefined') {
    const cookieArr = document.cookie.split(';');

    // Loop through the array elements
    for (let i = 0; i < cookieArr.length; i++) {
      const cookiePair = cookieArr[i].split('=');

      /* Removing whitespace at the beginning of the cookie name
      and compare it with the given string */
      if (name === cookiePair[0].trim()) {
        // Decode the cookie value and return
        return decodeURIComponent(cookiePair[1]);
      }
    }
  }

  // Return null if not found
  return null;
}

export const getSectionName = (collection, config) => {
  const sectionId = get(collection, ['metadata', 'section', 0, 'id']);
  const section = get(config, ['sections'], []).find((section) => section.id === sectionId) || {};
  return section['display-name'];
};

export const getSectionURL = (collection, config) => {
  const sectionId = get(collection, ['metadata', 'section', 0, 'id']);
  const section = get(config, ['sections'], []).find((section) => section.id === sectionId) || {};
  return section['section-url'];
};

export const getSectionDataWithID = (sectionId, config) => {
  if (!sectionId || !config) {
    return null;
  }
  const section = get(config, ['sections'], []).find((section) => section.id === sectionId) || {};
  return section;
};

export const deepSearch = (target, field, value) => {
  if (typeof target === 'object') {
    for (const key in target) {
      if (typeof target[key] === 'object') {
        deepSearch(target[key]);
      } else {
        if (key === field) {
          target[key] = value;
        }
      }
    }
  }
  return target;
};

export const replaceDate = (target, field) => {
  if (typeof target === 'object') {
    for (const key in target) {
      if (typeof target[key] === 'object') {
        replaceDate(target[key], field);
      } else {
        if (key === field) {
          target[key] = formatInTimeZone(target[key], 'Asia/Kolkata', DATE_FORMATS.DATE_TIME_ZONE);
        }
      }
    }
  }
  return target;
};

export const removeAuthorUrl = (target) => {
  if (typeof target === 'object') {
    for (const key in target) {
      if (typeof target[key] === 'object') {
        removeAuthorUrl(target[key]);
      } else {
        if (key === 'author') {
          target[key] = delete target[key].url;
        }
      }
    }
  }
  return target;
};

export const getCollectionTitle = (collection) => {
  if (!collection) {
    return null;
  }

  return get(collection, ['metadata', 'display-name'], get(collection, ['name'], null));
};
